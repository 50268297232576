.answer-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: max-content;
  padding: 16px;
  flex-wrap: wrap;
}

.answer-group > button {
  width: 100px;
  height: 30px;
  margin: 8px;
  border: 2px solid #32b4a6;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: medium;
  font-weight: bold;
  transition: 0.3s;
}

.answer-group > .radio {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.answer-group > input {
  width: 100%;
  padding: 16px;
  border-radius: 4px;
}

.answer-group > input:focus {
  border-color: #32b4a6;
  outline-color: #32b4a6;
}

.radio > div {
    display: flex;
    gap: 16px
}

@media only screen and (min-width: 768px) {
  .answer-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 65%;
    height: max-content;
    padding: 16px;
    flex-wrap: wrap;
  }

  .answer-group > button {
    width: 60px;
    height: 45px;
    margin: 16px;
    border: 2px solid #32b4a6;
    border-radius: 4px;
    background-color: #ffffff;
    font-size: medium;
    font-weight: bold;
    transition: 0.3s;
  }
}

.radio-item {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
}

/* Hide the browser's default checkbox */
.radio-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  
border-radius: 15px;
}

/* On mouse-over, add a grey background color */
.radio-item:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-item input:checked ~ .checkmark {
  background-color: #32b4a6;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-item .checkmark:after {
  left: 7px;
  top: 4px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
